import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layoutEn"
import Warehouses from "../components/warehouses"
import { Link } from "gatsby"

import documentos from "../images/documentos.svg"
import paquetes from "../images/paquetes.svg"
import exportaSimple from "../images/exporta-simple.svg"
import courier from "../images/expo-documentos.png"
import estrella from "../images/estrella-bca.svg"
//import cobertura from "../pdf/COBERTURA-GEOGRAFICA-Y-TIEMPOS-DE-TRANSITO-2022.pdf"

const ExportacionPage = () => {
  const [courierExpand, setCourierExpand] = React.useState(false)
  const [exportaExpand, setExportaExpand] = React.useState(false)
  const [cargaExpand, setCargaExpand ] = React.useState(false)

  return (
  <Layout
	seccion="exportacion"	
	title="Export"	
	linkTo="courier"
	>		
		<div id="courier" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-sm-6">
					<div className={"expTxt bgBlue "+(courierExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>International Courier Service</h2></div>
								{/*
								<p className="text-white">Realice sus envios a todo el mundo rápido y seguro.</p>
								
								<p className="text-white"
								   style={{
									   paddingTop: '10px',
									   marginBottom: '40px'
								   }}>Ofrecemos el mejor tiempo de tránsito.</p>
								
								
								<p className="text-white">Servicio de Transporte aéreo internacional de documentos <br/>y paquetería, puerta a puerta, hacia todo el mundo <br/>hasta 50kg de peso bruto y hasta Usd 3000 de valor fob.</p>
								*/}
								
								<p className="text-white">Door-to-door international air transport service <br/>of documents and packages worldwide.</p>
								{/* <br/>hasta 50kg de peso bruto y hasta Usd 3000 de valor Fob.</p>*/ }
								
								<p className="text-white requisitos">MONTHLY FUEL "18.58%"</p>

								<div id="expandCourier" className={"expand text-white "+(courierExpand ? 'invisible' : '')}
									onClick={() => setCourierExpand(!courierExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									MORE
								</div>
							</div>
					</div>
				</div>
				
					<div className={"col-sm-6 expImg courier"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={courierExpand ? 'expanded' : ''}></div>
					</div>
			</div>
			<AnimatePresence initial={false}>
				{courierExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
						<div className="row">
							<div className="col-sm-6 serviceExpand pr-0">
								<div className="bgBlue d-flex flex-column">
									<div className="leftExpand">
										<div className="courierTitle d-flex align-items-start">
											<img src={paquetes} />
											<h2 className="text-white">
											Economy
											{/*Servicio Courier<br/> Internacional Documentos */}
											</h2>
										</div>
										{/* <p>Economy Documentos</p> */}
										<p>Door-to-door international courier service for documents and packages up to 50 kg. and USD 3,000 FOB value with no priority shipping or delivery.</p>

										<p>A service designed to ship documents and packages worldwide at lower costs. We offer real-time shipment online tracking (Track &amp; Trace) with worldwide coverage.</p>
										
										<p>Need more information?</p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
										
{/* <p>Servicio diseñado para bajar sus costos a todo el<br/>
										mundo. Tiene seguimiento online y entrega puerta a
puerta.</p> 
										
										<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
										
										<p>Priority Documentos</p>
										<p>Servicio diseñado para envíos de documentos en tiempo
										récord. Tiene seguimiento online y entrega puerta a
										puerta.</p>
*/}
										
										{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>*/}
										
									</div>
									<img src={courier} className="imgExpandLeft d-none d-sm-block" 
									style={{marginTop: 'auto'}}/>
								</div>
							</div>
							<div className="col-sm-6 serviceExpand pl-0">
								<div className="bgBlue">
									<div className="courierTitle d-flex align-items-start">
										<img src={documentos} />
										<h2 className="text-white">
										Priority
										{/*Servicio Courier<br/> Internacional Paquetes */}
										</h2>
									</div>
									{/*
									<p>Economy Paquetes</p>
									<p>Servicio diseñado para bajar sus costos en muestras a<br/>
									todo el mundo. Tiene seguimiento online y entrega puerta<br/>
									a puerta, con un tiempo estimado de 4 a 6 días hábiles.</p>
									
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									<p>Priority Paquetes</p>
									<p>Servicio diseñado para envíos de paquetes con entregas<br/>
									en tiempo récord a todo el mundo. Tiene seguimiento<br/>
									online, atención al cliente a disposición y entrega puerta a<br/>
									puerta, con un tránsito estimado de 2 a 4 días hábiles.</p>
									*/ }
									
									<p>Door-to-door international courier service for documents and packages up to 50 kg. and USD 3,000 FOB value. Urgent shipments, with priority shipping and delivery.</p>
									
									<ul className="beneficios"
									style={{marginBottom: '20px'}}>
										<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
									</ul>
									<ul className="beneficios">
										<li><img src={estrella} alt="" title="" />Best transit times guaranteed.</li>
										<li><img src={estrella} alt="" title="" />We offer on-time local pickups and worldwide shipment.</li>
										<li><img src={estrella} alt="" title="" />Único Courier que ofrece packaging personalizado para cada necesidad. Our customer service team is ready to help you.</li>
										<li><img src={estrella} alt="" title="" />You will be assigned an account manager to provide guidance and support.</li>
									{/*<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Somos los únicos que operamos en el país como<br/> correo de correos.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Retiramos envíos desde cualquier ciudad de Argentina<br/> hacia el resto del mundo.</li>
										<li>
										<svg style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Ejecutivo de Cuentas y Customer Service exclusivo<br/> para cada cliente.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>A través de nuestra web podrá: Realizar pedidos de<br/>
										recolección online, solicitar cotización, consultar status de<br/>
									pedidos (Track &Trace). Cobertura Mundial</li>	*/}									
									</ul>
									
									<p>Need more information? </p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
										
									{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p> */}
									
									
									{/*<p>Para solicitar una cotización enviar un mail a
									expo@raiconet.com con los siguientes datos:</p>

									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origen.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-inline-block">Medidas (cm) o Peso Volumétrico<br/>
										(Largo x alto x ancho/5000).</span>
										</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido</li>
									</ul>
									*/ }
								</div>
							</div>
						</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="expSimple" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-sm-6 pr-0">
					<div className={"expTxt bgBlue "+(exportaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta"
								style={{marginBottom: '30px'}}
								>
									<h2>Simple Export<br/> Service</h2>
									<img id="exportaSimpleIcon" src={exportaSimple} alt="Exporta Simple" title="Exporta Simple" />
								</div>
								{/*
								
								<p className="text-white">Facilitamos su exportación.</p>
								
								<p className="text-white">Ideal para pymes, empresas regionales y pequeños emprendedores.<br/>
								Somos uno de los operadores nacionales en<br/>
formar parte de la Plataforma de Exporta Simple.</p> 
								
								<p className="text-white"
								style={{paddingRight: '0'}}
								>Es una herramienta diseñada por el Ministerio de Producción<br/> para facilitar las operaciones de exportación de las pymes, empresas regionales y pequeños emprendedores	
								{!exportaExpand ? '.'
								: <span> para <br/>exportar hasta USD 600.000 de valor FOB anual en envíos<br/> de hasta USD 15.000 y sin límite de peso. Pueden despacharse varios envíos juntos. <br/> No se requiere licencia de Exportador ni despachante y cuenta con reintegros a la exportación.</span>
								}
								</p>
*/}				
								{/*exportaExpand &&
								<p className="requisitos"><a href="https://exportasimple.raiconet.com/cotizar" target="_blank">CALCULATE YOUR SHIPPING COST</a></p>
								*/}

								<p className="text-white"
								style={{paddingRight: '0'}}
								>Door-to-door or door-to-airport air transport export service enhanced through the Simple Export digital platform.</p>
								
								{!exportaExpand && 
								<div className={"expand text-white "+(exportaExpand ? 'invisible' : '')}
									onClick={() => setExportaExpand(!exportaExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									MORE
								</div>
								}
								
								{/* !exportaExpand && 
								<div className={"expand text-white "+(exportaExpand ? 'invisible' : '')}
									style={{ marginLeft: '5px' }}
									onClick={ () => window.open("https://exportasimple.raiconet.com/cotizar")}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
	CALCULATE YOUR SHIPPING COST
								</div>
							*/}

								
								
				<AnimatePresence initial={false}>
				{exportaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
					<p>A tool designed by the Ministry of Production to facilitate export operations of SMEs, regional companies and small entrepreneurs to export up to USD 600,000 FOB annual value in shipments of up to USD 15,000 and with no weight limit. Several shipments can be shipped together.<br/>
                    No Exporter's license or broker is required and export refunds are included. 
                    </p>
								  
                        <p>Door-to-door:</p>

                        <p>Successfully delivery at your door is subject to the type of goods and the regulations in force in each country.</p>								  
                    </motion.section>
				)}
				</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-sm-6 expImg expSimple"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={exportaExpand ? 'expanded' : ''}></div>
				</div>
			</div>
			<AnimatePresence initial={false}>
				{exportaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
				<div className="row">
					<div className="col-sm-6 serviceExpand pr-0">
						<div className="bgBlue">
							<div className="leftExpand">								  
								  <p>Door-to-airport:</p>
								  
								  <p>We take care of the airline booking and when cargo arrives at the destination airport, it is the buyer's responsibility to release and pay the corresponding fees. Our customer service team will assist you to make the best choice. Local Tax ID (CUIT) and Fiscal Code with a Security Level 3 as a minimum requirement. 
The total annual invoicing of these exports shall not exceed the amount of US$600,000 FOB value per subject.
</p>
								  <p>Our customer service team will assist you to make the best choice.</p>
								
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Local Tax ID (CUIT) and Fiscal Code with a Security Level 3 as a minimum requirement.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
                                        The total annual invoicing of these exports shall not exceed the amount of US$600,000 FOB value per subject.
                                        </li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Each individual operation shall not exceed a FOB value of US$15,000.
                                        </li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
                                        The goods to be exported must be produced in the country, and free of prohibition, suspension or export quota.
                                        </li>										
									</ul>
								</div>
							</div>
						</div>
						<div className="col-sm-6 serviceExpand pl-0">
							<div className="bgBlue">
								<ul id="expSimpleULcomp">
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									Unlimited unit weight for each shipment.</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
                                    The packages should not exceed 145 cm wide by 180 cm high, with no length limit.
                                    </li>
								</ul>
								
								
								<p>We highly recommend that you consult the restrictions and limitations of each airline based on the destination.</p>

								<p>To request a custom quote, please email the following information to exposimple@raiconet.com:</p>

								<ul>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									Destination.</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
                                    Weight (Kg).</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									Dimensions (cm): length x height x width.</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Tariff position</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Number of packages</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB value (USD).</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Description of contents / type of goods</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cargo insurance (yes or no)</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Delivery terms</li>
								</ul>
								
								<p>Need more information?</p>

								<p className="requisitos"><a href="mailTo:exposimple@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
									
								{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p> */}
								
									
							</div>
						</div>
					</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="carga" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-sm-6 pr-0">
					<div className={"expTxt bgBlue "+(cargaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Air Cargo Service</h2></div>
								{/*
								<p className="text-white">Cobertura mundial para sus cargas internacionales de modo seguro y confiable. </p>
								
								<p className="text-white">Nos encargamos de hacer llegar su mercadería de la <br/>forma más eficaz y económica.</p>
								
								<p className="text-white">Servicio de CARGA AÉREA Internacional con la flexibilidad necesaria para adaptar la entrega según la necesidad de <br/>cada cliente, podrà elegir entre una entrega:<br/> PUERTA-PUERTA o PUERTA-AEROPUERTO.</p>
								*/ }
								
								<p
								style={{paddingRight: '0'}}
								className="text-white">Door-to-door or door-to-airport export air cargo service.</p>
								
								{/*!cargaExpand && */}
									<div id="expandCarga" className={"expand text-white "+(cargaExpand ? 'invisible' : '')}
										onClick={() => setCargaExpand(!cargaExpand)}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
		  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
		</svg>
										MORE
									</div>
								{/**/}
								<AnimatePresence initial={false}>
								{/* && cargaExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >									  
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Reservamos con anticipación espacios de bodegas con las principales compañías aéreas.</li>										
									</ul>
								  </motion.section>
								)*/}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-sm-6 expImg carga"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={cargaExpand ? 'expanded' : ''}></div>
				</div>
			</div>
			<AnimatePresence initial={false}>
				{cargaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
					<div className="row">
						<div className="col-sm-6 serviceExpand pr-0">
							<div className="bgBlue"
							style={{paddingBottom: '30px' }}
							>
								<div className="leftExpand">								  								
									 
									<p>Door to door:</p>
									  
									  <p>We pick up the cargo from our clients' address and coordinate the payment of releasing fees and delivery at destination.</p>
									  
									<p>Door to airport:</p>
									  
									<p>We take care of the customs documentation, we issue your Shipping Permit and transport the cargo through first class airlines to the recipient's nearest international airport.</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 serviceExpand pl-0">
							<div className="bgBlue">
								
								<ul className="beneficios"
								style={{marginTop: '0px' }} >
									<li><img src={estrella} alt="" title="" />
                                    Warehouse space can be booked in advance with the main airlines.</li>
									<li><img src={estrella} alt="" title="" />
									We provide constant updates on the status of your cargo until delivery.</li>
									<li><img src={estrella} alt="" title="" />
									We assist our clients in the issuance of the necessary documentation for a successful export.</li>
								</ul>
								
								
								<p 
								style={{ marginTop: '30px' }}
								className="requisitos">REMEMBER YOU MUST HAVE YOUR EXPORTER'S LICENSE ENABLED!</p>
								
								<p>To request a custom quote, please email the following information to exposimple@raiconet.com:</p>

									<ul
									style={{ marginBottom: '40px' }}
									>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Destination.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Weight (Kg).</li>
										<li><svg 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Dimensions (cm) or Volumetric Weight <br className="d-none d-sm-block" />
										(Length x height x width/5000).</span>
										</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB Value (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Description of contents.</li>
									</ul>
									
									<p>Need more information?</p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
										
									{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p> */}
																		
							</div>
						</div>
					</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<Warehouses />
  </Layout>
)
}
export default ExportacionPage
